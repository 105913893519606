.Field {
    width: var(--field-size);
    height: var(--field-size);
    display: flex;
    justify-content: center;
    align-items: center;
  }
.black {
    background-color: var(--color-black);
}
.white {
    background-color: var(--color-white);
}

