body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 2rem;


  --text-color: white;
  --background-color: #2b2b2b;
  --field-size: calc(100vh / 8);
  --color-black:#818e70;
  --color-white: #bdc4b4;

  background-color: var(--background-color);
  color: var(--text-color);
  

  display: flex;
  justify-content: center;
  align-items: center;
  
}
